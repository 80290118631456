import React, { Suspense } from 'react'
import { Container, Spinner, ExperimentalFeatureContext } from 'Elements'
import { ClarityContextProvider } from '../clarity/ClarityContextProvider'

interface Item {
  path: string
  container?: {
    allowTimeout?: boolean
    expand?: boolean
    offsetHeader?: boolean
    fullHeight?: boolean
  }
}

const items: Item[] = [
  {
    path: 'Admin/Campaign/Actions',
    container: { expand: true, offsetHeader: true }
  },
  {
    path: 'Admin/Campaign/Settings',
    container: { expand: true, offsetHeader: true }
  },
  {
    path: 'Admin/Campaign/Summary',
    container: { expand: true, offsetHeader: true }
  },
  {
    path: 'Admin/Campaign/Targets',
    container: { expand: true, offsetHeader: true }
  },
  {
    path: 'Admin/Campaigns',
    container: { expand: true, offsetHeader: true }
  },
  {
    path: 'Admin/Organizations',
    container: { expand: true, offsetHeader: true }
  },
  {
    path: 'Admin/Organizations/WorkSteps',
    container: { expand: true, offsetHeader: true }
  },
  {
    path: 'Admin/Reports',
    container: { expand: true, offsetHeader: true }
  },
  {
    path: 'Admin/SuperGroup',
    container: { expand: true, offsetHeader: true }
  },
  {
    path: 'Admin/SuperGroups',
    container: { expand: true, offsetHeader: true }
  },
  {
    path: 'Admin/Users',
    container: { expand: true, offsetHeader: true }
  },
  { path: 'Action/Community', container: { expand: true } },
  { path: 'Action/Resources', container: { expand: true } },
  { path: 'Action/Summary', container: { expand: true } },
  { path: 'EnergyFootprintForm', container: {} },
  { path: 'FuelFootprint', container: {} },
  { path: 'Home', container: { allowTimeout: false, expand: true } },
  { path: 'Login', container: { allowTimeout: false, expand: true } },
  { path: 'Navigation' },
  { path: 'Notifications' },
  {
    path: 'Onboarding',
    container: { fullHeight: true }
  },
  {
    path: 'Organization/Campaign/Actions',
    container: { expand: true }
  },
  {
    path: 'Organization/Campaign/Settings',
    container: { expand: true }
  },
  {
    path: 'Organization/Campaign/Summary',
    container: { expand: true }
  },
  {
    path: 'Organization/Campaign/Targets',
    container: { expand: true }
  },
  {
    path: 'Organization/Campaigns',
    container: { expand: true }
  },
  {
    path: 'Organization/Landing',
    container: { expand: true }
  },
  {
    path: 'Organization/Leaderboard',
    container: { expand: true }
  },
  {
    path: 'Organization/Memberships',
    container: { expand: true }
  },
  {
    path: 'Organization/Progress',
    container: { expand: true }
  },
  {
    path: 'Organization/PublicTeams',
    container: { expand: true }
  },
  {
    path: 'Organization/Reports',
    container: { expand: true }
  },
  {
    path: 'Organization/Settings',
    container: { expand: true }
  },
  {
    path: 'Organization/Teams',
    container: { expand: true }
  },
  { path: 'Person/Actions', container: { expand: true } },
  {
    path: 'Person/Footprints',
    container: { expand: true }
  },
  {
    path: 'Person/Footprints/Household',
    container: { allowTimeout: true, expand: true }
  },
  {
    path: 'Person/Footprints/Insights',
    container: { allowTimeout: true, expand: true }
  },
  {
    path: 'Person/Goals',
    container: { allowTimeout: true, expand: true }
  },
  {
    path: 'Person/Invitations',
    container: { expand: true }
  },
  {
    path: 'Person/Notifications',
    container: { expand: true }
  },
  { path: 'Person/Progress', container: { expand: true } },
  {
    path: 'Person/Settings/Account',
    container: { expand: true }
  },
  {
    path: 'Person/Settings/Notifications',
    container: { expand: true }
  },
  { path: 'Person/Todo', container: { expand: true } },
  {
    path: 'PublicOrganization',
    container: { expand: true }
  },
  {
    path: 'PublicOrganization/Join',
    container: { expand: true }
  },
  { path: 'Registration', container: { expand: true } },
  { path: 'Session' },
  {
    path: 'SuperGroup/Leaderboard',
    container: { expand: true }
  },
  {
    path: 'SuperGroup/Progress',
    container: { expand: true }
  },
  { path: 'Team/Leaderboard', container: { expand: true } },
  { path: 'Team/Memberships', container: { expand: true } },
  { path: 'Team/Progress', container: { expand: true } },
  { path: 'Team/Settings', container: { expand: true } },
  { path: 'Team/AssignUsers', container: { expand: true } },
  {
    path: 'User/EmailPreferences',
    container: { expand: true }
  }
]

const itemSettings = Object.fromEntries(
  items.map((item) => {
    return [
      item.path,
      {
        import: React.lazy(
          async () =>
            await import(
              /* webpackChunkName: "[request]" */ `Containers/${item.path}`
            )
        ),
        container: item.container || {}
      }
    ]
  })
)

const View = ({ showExperimental, ...props }) => {
  const viewSettings = itemSettings[props.Container]
  const Component = viewSettings.import

  const Content = () => (
    <ExperimentalFeatureContext.Provider value={{ showExperimental }}>
      <ClarityContextProvider {...props}>
        <Suspense fallback={<Spinner absolute />}>
          <Component {...props} />
        </Suspense>
      </ClarityContextProvider>
    </ExperimentalFeatureContext.Provider>
  )

  if (viewSettings.container) {
    const containerProps = {
      ...props.containerProps,
      ...viewSettings.container
    }

    return (
      <Container {...containerProps}>
        <Content />
      </Container>
    )
  }

  return <Content />
}

export default View
