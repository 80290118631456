import useLocalI18n from 'Hooks/LocalI18n'
import React from 'react'
import { observer } from 'mobx-react'
import {
  Button,
  ButtonIcons,
  LoadMoreButton,
  LinksList,
  Spinner
} from 'Elements'
import { useListFocus } from 'Hooks/ListFocus'
import * as styles from './styles.module.scss'
import { IObservableArray } from 'mobx'
import { SerializedPagination } from '@giki_zero/javascript/constants'

interface OrganizationProps {
  id: string
  attributes: {
    name?: string
    logo?: string
  }
  links: {
    html_admin_memberships: string
  }
}

interface OrganizationsProps {
  fetchOrganizations: (props: { number: number }) => Promise<void>
  loaded: boolean
  loading: boolean
  onCreate: () => void
  onDelete: (organizationId: string) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  organizations: IObservableArray<unknown>
  pagination: SerializedPagination
}

const Organizations = observer(
  ({
    fetchOrganizations,
    loaded,
    loading,
    onCreate,
    onDelete,
    organizations,
    pagination
  }: OrganizationsProps) => {
    const { I18n } = useLocalI18n('molecules/Organizations/Lang')

    const { _listRef, _listFocus } = useListFocus('a')

    const _fetchOrganizations = () => {
      fetchOrganizations({ number: pagination.next })?.then(_listFocus)
    }

    return (
      <div className={styles.Organizations}>
        <section className={styles.Organizations__actions}>
          <Button
            onClick={onCreate}
            ariaLabel={I18n.t('organizations.actions.create')}
            icon={ButtonIcons.CreateIcon}
            large
          />
        </section>
        <section className={styles.Organizations__content}>
          {loading && (
            <div className={styles.Organizations__loader}>
              <Spinner absolute />
            </div>
          )}
          {loaded && organizations.length > 0 && (
            <LinksList
              items={organizations.map((organization: OrganizationProps) => {
                return {
                  link: organization.links.html_admin_memberships,
                  linkLabel: I18n.t('organizations.actions.view'),
                  secondaryLinks: [
                    {
                      linkLabel: I18n.t('organizations.actions.workSteps'),
                      link: `/admin/organizations/${organization.id}/work-steps`
                    },
                    {
                      onClick: () => onDelete(organization.id),
                      linkLabel: I18n.t('organizations.actions.delete'),
                      linkIcon: ButtonIcons.DeleteIcon
                    }
                  ],
                  title: organization.attributes.name,
                  avatar: organization.attributes.logo,
                  avatarFirstName: organization.attributes.name
                }
              })}
              ref={_listRef}
            />
          )}
          {loaded && !organizations.length && (
            <div className={styles.Organizations__placeholder}>
              {I18n.t('organizations.placeholder')}
            </div>
          )}
          {pagination.next && (
            <div className={styles.Organizations__pagination}>
              <LoadMoreButton
                loading={loading}
                onLoadMore={_fetchOrganizations}
              />
            </div>
          )}
        </section>
      </div>
    )
  }
)

export default Organizations
